/* global Component, axios, __lodash__ */
class centerCostModalComponent extends Component {

    static name() {
        return "centerCostModalComponent";
    }

    static componentName() {
        return "centerCostModalComponent";
    }

    getProps() {
        return  {
            personList:{
                type:Array,
                default:function (){
                    return [];
                }
            },
            recordKey:{
                type:String,
                default:'CC_Modal'
            },
            paramCenterCostRecord:{
                type:Object,
                default:function (){
                    return window.centerCostTemplate;
                }
            },
            saveCenterCost:{
                type:Function
            },
            addCenterCost:{
                type:Function
            }
        };
    }

    data() {
        return {
            restoreRecord:null,
            mode:"create",
            selectOption:"contacts",
            response: null,
            alertError: null,
            ContactsOptions: {
                "PersonCode": {label: "Person Code", linkto: "Person", pasteField: 'Code', showField:["Name","LastName"]},
                "PersonName": {label: "Person Name", readonly: true},
                "IsMainContact": {label: "Main Contact", type: "checkbox"},
            },
            LiberatorsOptions: {
                "PersonCode": {label: "Person Code", linkto: "Person", pasteField: 'Code', showField: "Name"},
                "PersonName": {label: "Person Name", type: "PersonName", readonly: true},
                "CanLiberate": {label: "Can Liberate", type: "checkbox"},
                "OnlyModified": {label: "Only Modified", type: "checkbox"}
            }
        };
    }

    created(){
        return async function (){
            //console.log('CC-Load');
            this.restoreRecord = Object.freeze(this.paramCenterCostRecord);
            this.openModal = this.openModal.bind(this);
            this.contactAfterEditRow = this.contactAfterEditRow.bind(this);
            this.liberatorAfterEditRow = this.liberatorAfterEditRow.bind(this);
            this.subscribeEvent('openModalCenterCost',this.openModal);
        };
    }

    getComputed() {
        return {
            centerCostRecord:function (){
                if(this.paramCenterCostRecord)
                    return this.paramCenterCostRecord;
                else
                    return window.centerCostTemplate;
            }
        };
    }

    getMethods() {
        return {
            openModal:this.openModal,
            contactAfterEditRow:this.contactAfterEditRow,
            liberatorAfterEditRow:this.liberatorAfterEditRow,
            windowAction: this.windowAction,
            clearForm:this.clearForm
        };
    }

    openModal(){
        $(this.$refs["centerCostModal"]).modal({backdrop: 'static', keyboard: false,show:true});
    }

    contactAfterEditRow(row,event,mustAdded){
        if(event.field == 'PersonCode'){
            if(event.linkToData.length > 0){
                row.fields.PersonName = `${event.linkToData[0]['Name']} ${event.linkToData[0]['LastName']}`;
            }
        }
        if(mustAdded)
            this.centerCostRecord.fields['Contacts'].push(row);
    }

    liberatorAfterEditRow(row,event,mustAdded){
        if(event.field == 'PersonCode'){
            if(event.linkToData.length > 0){
                row.fields.PersonName = `${event.linkToData[0]['Name']} ${event.linkToData[0]['LastName']}`;
                row.fields['CanLiberate'] = true;
            }
        }
        if(event.field == 'OnlyModified')
            row.fields['CanLiberate'] = !row.fields['OnlyModified'];

        if(event.field == 'CanLiberate')
            row.fields['OnlyModified'] = !row.fields['CanLiberate'];
        if(mustAdded)
            this.centerCostRecord.fields['Liberators'].push(row);
    }

    async windowAction(){
        let centerCostRecord = __lodash__.cloneDeep(this.centerCostRecord);
        if(centerCostRecord.fields.syncVersion)
            centerCostRecord.fields.syncVersion = centerCostRecord.fields.syncVersion + 1;
        else
            centerCostRecord.fields.syncVersion = 1;

        /*let isNew = false;
        if(!centerCostRecord.fields.Code)
            isNew = true;
        */

        let response = await axios.post("/ecommerce/api/saveCenterCost", {action:"save",record:JSON.stringify(centerCostRecord)});
        if(response.data.success) {
            this.emitEvent('addCenterCost',response.data.record);
            $(this.$refs["centerCostModal"]).modal('hide');
            this.$store.dispatch('showNotificacion',{title:`Centro de Costo` , message: `Agregado nuevo centro de Costo ${response.data.record.fields.Code}`,type: 'info'});
        }
        else{
            this.$store.dispatch('showNotificacion',{title:`Error`, message: `Error al guardar centro de Cost`,type: 'error'});
        }

    }
    clearForm() {
        this.emitEvent("editCenterCost",null);
        this.emitEvent(`clearAwesonLinkTo_Contacts__PersonCode__Person`);
        this.emitEvent(`clearAwesonLinkTo_Liberators__PersonCode__Person`);
        $(this.$refs["centerCostModal"]).modal('hide');
    }

    getTemplate(){
        return `<div  class="modal fade item-detail-modal" ref="centerCostModal" id="centerCostModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Cost Center")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close" click="clearForm">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                                <div class="col-sm-12">
                                      <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" v-if="alertError">
                                           <strong>{{tr(alertError)}}!</strong>
                                           <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError = null">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                       </div>
                                </div>
                               <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="Name" class="form-label">{{tr('Name')}}</label>
                                            <input id="Name" v-model="centerCostRecord.fields.Name" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                           <label for="Comment" class="form-label">{{tr('Delivery Address')}}</label>
                                           <selectLinkToComponent recordKey="CC_Modal" table="CompanyAddress" paste="Code" showField="Name" :selected="centerCostRecord.fields.DelAddressCode"></selectLinkToComponent>
                                       </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                           <label for="Comment" class="form-label">{{tr('Comment')}}</label>
                                            <textarea class="form-control" rows="5" v-model="centerCostRecord.fields.Comment"></textarea>
                                       </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                     <ul class="nav nav-tabs">
                                          <li class="nav-item">
                                            <a class="nav-link" :class="{active: selectOption=='contacts'}" href="#" @click="selectOption='contacts'">{{tr('Contacts')}}</a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" :class="{active: selectOption=='liberators'}" href="#" @click="selectOption='liberators'" >{{tr('Liberators')}}</a>
                                          </li>
                                          <li class="nav-item">
                                            <a class="nav-link" :class="{active: selectOption=='restrictions'}" href="#" @click="selectOption='restrictions'" >{{tr('Restrictions')}}</a>
                                          </li>
                                      </ul>
                                      <div class="tab-content" id="myTabContent">
                                          <div  :class="{active: selectOption=='contacts',show: selectOption=='contacts'}" class="tab-pane fade pt-2" id="contacts" role="tabpanel" aria-labelledby="home-tab">
                                                <recordMatrixComponent rowField="Contacts" :record="centerCostRecord" :headers="ContactsOptions" :afterEditRow="contactAfterEditRow"></recordMatrixComponent>
                                          </div>
                                          <div  :class="{active: selectOption=='liberators',show: selectOption=='liberators'}" class="tab-pane fade pt-2" id="liberators" role="tabpanel" aria-labelledby="profile-tab">
                                                <recordMatrixComponent rowField="Liberators" :record="centerCostRecord"  :headers="LiberatorsOptions" :afterEditRow="liberatorAfterEditRow"></recordMatrixComponent>
                                          </div>
                                          <div  :class="{active: selectOption=='restrictions',show: selectOption=='restrictions'}" class="tab-pane fade pt-2" id="restrictions" role="tabpanel" aria-labelledby="contact-tab">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="custom-control custom-switch">
                                                          <input class="custom-control-input" id="LimitMaxSalesAmounts" v-model="centerCostRecord.fields.LimitMaxSalesAmounts" type="checkbox">
                                                          <label for="LimitMaxSalesAmounts" class="custom-control-label">{{tr('Apply Max. Sales Limits')}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="MinSalesAmount" class="form-label">{{tr('Minimum Sales Amount')}}</label>
                                                            <input id="MinSalesAmount" v-model="centerCostRecord.fields.MinSalesAmount" type="number" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="MaxSalesAmount" class="form-label">{{tr('Maximum Sales Amount')}}</label>
                                                            <input id="MaxSalesAmount" v-model="centerCostRecord.fields.MaxSalesAmount" type="number" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="MaxMonthlySalesAmount" class="form-label">{{tr('Maximum Sales Amount Per Month')}}</label>
                                                            <input id="MaxMonthlySalesAmount" v-model="centerCostRecord.fields.MaxMonthlySalesAmount" type="number" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="QtyAllowedMonthly" class="form-label">{{tr('Quantity Allowed Monthly Orders')}}</label>
                                                            <input id="QtyAllowedMonthly" v-model="centerCostRecord.fields.QtyAllowedMonthly" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <h6>"Rango de Fecha Dentro del Mes"</h6>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="FromDay" class="form-label">{{tr('From Date')}}</label>
                                                            <input id="FromDay"  v-model="centerCostRecord.fields.FromDay" type="number" min="1" max="31" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="ToDay" class="form-label">{{tr('To Date')}}</label>
                                                            <input id="ToDay" v-model="centerCostRecord.fields.ToDay" type="number" min="1" max="31" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                          </div>
                                      </div>
                                </div>
                          </div>
                          <div class="modal-footer">
                                <button class="btn secondary-btn" @click="windowAction" >{{tr('Save')}}</button>
                                <button class="btn secondary-btn" @click="clearForm" data-dismiss="modal" >{{tr('Cancel')}}</button>
                          </div>
                      </div>
                  </div>
              </div>`;
    }

}

centerCostModalComponent.registerComponent();